import { Box, CircularProgress, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import MuiOutlineButton from 'components/UI/MuiOutlineButton';

import { IOption } from 'models/app';
import ShowcaseList from './partials/ShowcaseList';
import { IMediaQuery } from 'types/mediaQuery';
import useResponsive from 'hooks/useResponsive';
import { Feature } from 'models/api';
import { ShowcaseAddInput, ShowcaseListItem } from 'models/showcase';
import { useMutation, useQueryClient } from 'react-query';
import { useAlert } from 'contexts/alertContext';
import { UploadShowCaseImage, addShowcase, deleteShowcase, generateShowcaseTemplate, importShowcaseTemplate } from 'framework/showcase';
import AddShowCase from './partials/AddShowcase';
import { BASE_URL } from 'constants/api';
import { useCustomAlert } from 'contexts/customAlertContext';
import ShowcaseBulkActions from './partials/ShowcaseBulkActions';
import useShowcaseStore from 'store/showcase';
import { addTag } from 'framework/tags';
import { addTopic } from 'framework/topics';
import useApp from 'hooks/useApp';
import PrimeReactModal from 'components/UI/PrimeReactModal';
import { ITopicInput } from 'models/topic';
import { BackOfficeHeader } from 'helpers/utils';
import { Permission } from 'models/permission';

interface Props
{
	feature: Feature;
}

const ShowCases: React.FC<Props> = ({ feature }) =>
{
	const mediaParams: IMediaQuery = { query: 'up', key: 'md' };
	const mdUp = useResponsive(mediaParams);
	const queryClient = useQueryClient();

	const [openAddShowCase, setOpenAddShowCase] = React.useState(false);

	const [loading, setLoading] = React.useState(false);
	const [addFormData, setAddFormData] = React.useState<ShowcaseAddInput>({} as ShowcaseAddInput); // for form data
	const [reset, setReset] = React.useState(false); // for form data
	const params = useShowcaseStore((state) => state.params);
	const setParams = useShowcaseStore((state) => state.setParams);
	const { push } = useApp();
	const { setAlert } = useAlert();
	const { setCustomAlert } = useCustomAlert();

	let permissions: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissions?.find((item) => item.PermissionId === 4);

	useEffect(() =>
	{
		if (!currentPagePermissions?.HasReadPermission)
		{
			push('/404');
		}
	}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		, [currentPagePermissions]);

	const addShowcaseMutation = useMutation({
		mutationFn: (createInput: ShowcaseAddInput) =>
		{
			return addShowcase(createInput);
		},
		onSuccess: () =>
		{
			setParams(`?${params}`);
		}
	});

	const deleteShowcaseMutation = useMutation({
		mutationFn: (id: number) =>
		{
			return deleteShowcase(id);
		},
		onSuccess: () =>
		{
			setParams(`?${params}`);
		}
	});
	const generateShowcaseTemplateMutation = useMutation({
		mutationFn: () =>
		{
			return generateShowcaseTemplate();
		},
		onSuccess: () =>
		{
			setParams(`?${params}`);
		}
	});
	const importShowcaseTemplateMutation = useMutation({
		mutationFn: (data: FormData) =>
		{
			return importShowcaseTemplate(data);
		},
		onSuccess: () =>
		{
			setParams(`?${params}`);
		}
	});
	const UploadShowCaseImageMutation = useMutation({
		mutationFn: (data: FormData) =>
		{
			return UploadShowCaseImage(data);
		}
	});

	const addTagMutation = useMutation({
		mutationFn: (tagName: string) =>
		{
			return addTag(tagName);
		},
		onSuccess: () =>
		{
			queryClient.invalidateQueries('tags');
		}
	});
	const addTopicMutation = useMutation({
		mutationFn: (data: ITopicInput) =>
		{
			return addTopic(data);
		},
		onSuccess: () =>
		{
			queryClient.invalidateQueries('topics');
		}
	});

	const handleOpenAddShowCase = () => setOpenAddShowCase(true);
	const handleOpenEditShowcase = (showcase: ShowcaseListItem) =>
	{
		push(`/${feature.slug}/${showcase?.Id!}`);
	};
	const handleClose = () =>
	{
		setOpenAddShowCase(false);
		handleReset();
		setAddFormData({} as ShowcaseAddInput);
	};
	const handleChangeFormInputAdd = (name: string, value: any) =>
	{
		if (name === 'Attachment')
		{
			return handleUploadShowCaseImage(value);
		} else return setAddFormData({ ...addFormData, [name]: value });
	};

	// const handleOpenLoading = () => setLoading(true);
	const handleCloseLoading = () => setLoading(false);
	const handleReset = () =>
	{
		setReset(true);
		setTimeout(() =>
		{
			setReset(false);
		}, 1000);
	};

	const handleAddNewCase = async () =>
	{
		if (addFormData.ShowCaseTags && typeof addFormData.ShowCaseTags !== 'string' && addFormData.ShowCaseTags.length > 0)
			Object.assign(addFormData, { ShowCaseTags: addFormData.ShowCaseTags.map((item: IOption) => item.value).join(',') });

		let createInput: ShowcaseAddInput = {
			Subject: addFormData.Subject,
			Description: addFormData.Description,
			TopicId: addFormData.TopicId,
			LanguageId: addFormData.LanguageId,
			ShowCaseTags: addFormData.ShowCaseTags
		};
		try
		{
			let res = await addShowcaseMutation.mutateAsync(createInput);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Showcase added successfully',
				type: 'success'
			});
			handleCloseLoading();
			handleClose();
		} catch (err: any)
		{
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			handleCloseLoading();
		}
	};

	const handleDeleteShowcase = async (id: number) =>
	{
		try
		{
			let res = await deleteShowcaseMutation.mutateAsync(id);
			if (res.Success === 0) throw new Error(res.Message);
			handleClose();
			setAlert({
				open: true,
				message: 'Showcase deleted successfully',
				type: 'success'
			});
		} catch (err: any)
		{
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
	};

	const handleGenerateTemplate = async () =>
	{
		try
		{
			let res = await generateShowcaseTemplateMutation.mutateAsync();
			if (!res.Data) throw new Error('Failed to generate template');
			else
			{
				window.open(res.Data.replace('http://localhost:3000', BASE_URL).replace('http://scofolio.tridmark.com', BASE_URL));
			}
		} catch (err: any)
		{
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
	};
	const handleImportTemplate = async (file: File) =>
	{
		const formData = new FormData();
		formData.append('File', file);
		try
		{
			let res = await importShowcaseTemplateMutation.mutateAsync(formData);
			if (res.Success === 0) throw new Error(res.Message);
			else if (res.Data?.ValidationResults?.length > 0)
			{
				setCustomAlert({
					open: true,
					messages: res.Data.ValidationResults.map((item: any) => item.Message),
					type: 'error'
				});
			} else
				setCustomAlert({
					open: true,
					messages: ['Template imported successfully'],
					type: 'success'
				});
		} catch (err: any)
		{
			setCustomAlert({
				open: true,
				messages: [err?.response?.data?.Message] || [err.message] || ['Something went wrong'],
				type: 'error'
			});
		}
	};
	const handleUploadShowCaseImage = async (file: File) =>
	{
		const formData = new FormData();
		formData.append('AttachmentPath', file);
		try
		{
			let res = await UploadShowCaseImageMutation.mutateAsync(formData);
			if (res.Success === 0) throw new Error(res.Message);
			handleChangeFormInputAdd('AttachmentpPath', res.Data.replace('http://scofolio.tridmark.com', BASE_URL).replace('https://api.scofolio.com', BASE_URL));
		} catch (err: any)
		{
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
	};

	const handleAddTag = async (tagName: string) =>
	{
		if (!tagName) return;
		try
		{
			let res = await addTagMutation.mutateAsync(tagName);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Tag added successfully',
				type: 'success'
			});
		} catch (err: any)
		{
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
	};

	const handleAddTopic = async (topicName: string) =>
	{
		if (!topicName) return;
		try
		{
			let res = await addTopicMutation.mutateAsync({ Name: topicName });
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Topic added successfully',
				type: 'success'
			});
		} catch (err: any)
		{
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
	};

	return (
		<Box m={ 5 }>
			<BackOfficeHeader title="Display Showcases" />

			<Grid container mb={ 2 }>
				{ !mdUp && !!currentPagePermissions?.HasModifyPermission && (
					<Grid item md={ 12 } mb={ 2 }>
						<ShowcaseBulkActions OpenAddShowCase={ handleOpenAddShowCase } GenerateTemplate={ handleGenerateTemplate } ImportTemplate={ handleImportTemplate } />
					</Grid>
				) }
				<Grid item lg={ 7 } md={ 6 } sm={ 12 }>
					<ShowcaseList openEdit={ handleOpenEditShowcase } onDelete={ handleDeleteShowcase } />
				</Grid>
				{ mdUp && !!currentPagePermissions?.HasModifyPermission && (
					<Grid item lg={ 5 } md={ 6 } sm={ 12 }>
						<ShowcaseBulkActions OpenAddShowCase={ handleOpenAddShowCase } GenerateTemplate={ handleGenerateTemplate } ImportTemplate={ handleImportTemplate } />
					</Grid>
				) }
			</Grid>
			{ openAddShowCase && (
				<PrimeReactModal
					open={ openAddShowCase }
					onClose={ handleClose }
					title="Add Single Showcase"
					children={ <AddShowCase onChange={ handleChangeFormInputAdd } state={ addFormData } reset={ reset } onAddTag={ handleAddTag } onAddTopic={ handleAddTopic } /> }
					actions={
						<>
							<MuiOutlineButton
								variant="outlined"
								size="medium"
								sx={ {
									backgroundColor: '#F0F5FD',
									color: '#8B8BAE',
									fontWeight: 400,
									border: 'none'
								} }
								onClick={ handleClose }>
								Cancel
							</MuiOutlineButton>
							<MuiOutlineButton
								variant="outlined"
								size="medium"
								sx={ {
									backgroundColor: '#0A94D1',
									color: '#fff',
									fontWeight: 400,
									border: 'none',
									'&:hover': { color: '#000' }
								} }
								disabled={ loading || !addFormData.Subject || !addFormData.TopicId || !addFormData.LanguageId }
								onClick={ handleAddNewCase }>
								Add
								<CircularProgress size={ 10 } sx={ { mx: 2, display: loading ? '' : 'none' } } />
							</MuiOutlineButton>
						</>
					}
				/>
			) }
		</Box>
	);
};

export default ShowCases;
