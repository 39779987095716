import { CircularProgress, Grid, Stack } from '@mui/material';
import MuiOutlineButton from 'components/UI/MuiOutlineButton';
import { ShowcaseDetails, ShowcaseEditInput, ShowcaseGroups, ShowcaseInteractionSummary } from 'models/showcase';
import { Tag } from 'models/api';
import React, { useState } from 'react';
import { UploadShowCaseImage } from 'framework/showcase';
import { useMutation } from 'react-query';
import { BASE_URL } from 'constants/api';
import { useAlert } from 'contexts/alertContext';
import { Permission } from 'models/permission';
import { Group, OrganizationWithGroups } from 'models/organization';

interface Props
{
	showcase: ShowcaseDetails;
	showcaseInteractions: ShowcaseInteractionSummary[];
	organizationsWithGroups: OrganizationWithGroups[];
	onSubmit: (data: ShowcaseEditInput) => void;
	onDelete: (id: number) => void;
	onAddTag: (tagName: string) => void;
	onAddTopic: (topicName: string) => void;
	loading: boolean;
}

const BasicInfo = React.lazy(() => import('./BasicInfo'));
const Tags = React.lazy(() => import('./ShowcaseTags'));
const InsightsAndActivity = React.lazy(() => import('./InsightsAndActivity'));
const InteractionReports = React.lazy(() => import('./InteractionReports'));
const InteractionsDetails = React.lazy(() => import('./ShowcaseInteractions/ShowcaseInteractions'));
const ShowcaseStatus = React.lazy(() => import('./ShowcaseStatus'));
const MoreDetails = React.lazy(() => import('./MoreDetails'));
const ShowcaseProperties = React.lazy(() => import('./ShowcaseProperties'));

const DetailsAndActivity: React.FC<Props> = ({ onSubmit, showcase, showcaseInteractions, organizationsWithGroups, onDelete, onAddTopic, onAddTag, loading }) =>
{
	let permissions: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissions?.find((item) => item.PermissionId === 4);
	const UploadShowCaseImageMutation = useMutation({
		mutationFn: (data: FormData) =>
		{
			return UploadShowCaseImage(data);
		}
	});

	const { setAlert } = useAlert();



	let ShowcaseTags = showcase.tags.map((tag: Tag) => ({ label: tag.Name, value: `${tag.Id}` }));

	const defaultValue: ShowcaseEditInput = {
		ShowCaseId: showcase.basicInfo?.Id,
		ShowcaseTags,
		GroupIds: showcase.groups.filter(a => a.IsSelected).map((item: ShowcaseGroups) => ({
			label: item.GroupName,
			value: `${item.GroupId}`
		})),
		StatusId: showcase.basicInfo.StatusId,
		PriorityId: showcase.basicInfo.PriorityId,
		LanguageId: showcase.basicInfo.LanguageId,
		TopicId: showcase.basicInfo.TopicId,
		Organizations: showcase.groups.filter(a => a.IsSelected).map((item: ShowcaseGroups) => ({ label: item.OrganizationName, value: `${item.OrganizationId}` })),
		Description: showcase.basicInfo.Description,
		Subject: showcase.basicInfo.Subject,
		openEditDescription: false,
		openAddTags: false,
		openEditTags: false,
		openAddTopic: false,
		newTag: '',
		newTopic: ''
	};
	const [state, setState] = useState<ShowcaseEditInput>(defaultValue);

	const onChange = (name: string, value: any) =>
	{
		if (name === 'Attachment')
		{
			return handleUploadShowCaseImage(value);
		}
		if (name === 'Organizations')
		{
			// if select an organization, add all groups of that organization to selected groups 
			// if unselect an organization, remove all groups of that organization from selected groups
			// note that: value is an array of selected objects {label: string, value: string}
			let selectedOrganizationIds: string[] = value.map((item: any) => item.value);
			let selectedGroupIds: string[] = state.GroupIds.map((item: any) => item.value);
			let selectedGroups: Group[] = organizationsWithGroups
				.filter((item) => selectedOrganizationIds.includes(item.UserOrganizationId.toString()))
				.map((item) => item.Groups)
				.flat();
			let newGroupIds: string[] = selectedGroups.map((item) => item.GroupId.toString());
			let allGroupIds: string[] = [...selectedGroupIds, ...newGroupIds];
			let newSelectedGroup = selectedGroups.filter((item) => allGroupIds.includes(item.GroupId.toString())).map((item) => ({ label: item.GroupName, value: `${item.GroupId}` }));

			setState((prevState) => ({ ...prevState, [name]: value, GroupIds: newSelectedGroup }));

		}
		else
		{
			return setState((prevState) => ({ ...prevState, [name]: value }));
		}
	};

	const handleUploadShowCaseImage = async (file: File) =>
	{
		const formData = new FormData();
		formData.append('AttachmentPath', file);
		try
		{
			let res = await UploadShowCaseImageMutation.mutateAsync(formData);
			if (res.Success === 0) throw new Error(res.Message);
			onChange('AttachmentpPath', res.Data.replace('http://scofolio.tridmark.com', BASE_URL).replace('https://api.scofolio.com', BASE_URL));
		} catch (err: any)
		{
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
	};

	const handleSubmit = () =>
	{
		onSubmit(state);
	};

	return (
		<Grid container spacing={ 4 } py={ 2 }>
			<Grid item lg={ 8 } md={ 12 }>
				<Stack direction={ 'column' } spacing={ 2 }>
					{/* header & description */ }
					<BasicInfo showcase={ showcase } onChange={ onChange } state={ state } />
					{/* tags */ }
					<Tags showcase={ showcase } onChange={ onChange } state={ state } onAddTag={ onAddTag } />
					{/* Insights & Activity */ }
					<InsightsAndActivity showcase={ showcase } />
					{/* How are users interacting? */ }
					<InteractionReports showcase={ showcase } />
					{/* Interactions details */ }
					<InteractionsDetails showcaseInteractions={ showcaseInteractions } showcase={ showcase } />
				</Stack>
			</Grid>
			<Grid item lg={ 4 } md={ 12 }>
				<Stack spacing={ 2 }>
					{/* Audience Segmentation */ }
					{/* <AudienceSegmentation showcase={showcase} /> */ }
					{/* Showcase Status */ }
					<ShowcaseStatus showcase={ showcase } onChange={ onChange } state={ state } />
					{/* More Details And Activity */ }
					<MoreDetails showcase={ showcase } onChange={ onChange } state={ state } onAddTopic={ onAddTopic } organizationsWithGroups={ organizationsWithGroups } />
					{/* Showcase Properties */ }
					<ShowcaseProperties showcase={ showcase } />

					<Grid container justifyContent={ 'space-between' } sx={ { borderTop: 1, borderColor: 'divider' } } py={ 1 }>
						{ !!currentPagePermissions?.HasDeletePermission && (
							<MuiOutlineButton
								color="error"
								variant="outlined"
								sx={ {
									border: 'none',
									color: 'error.main'
								} }
								size="small"
								onClick={ () => onDelete(showcase.basicInfo.Id) }>
								Delete Showcase
							</MuiOutlineButton>
						) }

						{ !!currentPagePermissions?.HasModifyPermission && (
							<MuiOutlineButton
								color="primary"
								variant="outlined"
								sx={ {
									border: 'none',
									color: 'primary.main'
								} }
								size="small"
								disabled={ loading }
								onClick={ handleSubmit }>
								Save
								<CircularProgress size={ 10 } sx={ { mx: 2, display: loading ? '' : 'none' } } />
							</MuiOutlineButton>
						) }
					</Grid>
				</Stack>
			</Grid>
		</Grid>
	);
};

export default DetailsAndActivity;
