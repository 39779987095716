import { Button, ButtonProps } from "@mui/material";

interface Props extends ButtonProps {
  children: React.ReactNode;
  onClick: (e?:any) => void;
}

const MuiOutlineButton: React.FC<Props> = (props: Props) => {
  const { sx, children, onClick, ...rest } = props;
  return (
    <Button onClick={onClick} variant="outlined" disableElevation disableRipple sx={{ ...sx }} {...rest}>
      {children}
    </Button>
  );
};

export default MuiOutlineButton;
