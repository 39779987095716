import { CircularProgress, Container, Divider, Grid, IconButton, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { InputTypes } from 'constants/enums';
import { IFormField } from 'models/app';
import React, { useCallback, useState } from 'react';
import { IOption } from 'models/app';
import { Language, ResponseResult } from 'models/api';
import ActionLoader from 'components/UI/ActionLoader';
import { useQueryClient } from 'react-query';
import { getQuestionById, getQuestionTypes } from 'framework/testBank';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import { IQuestion, IQuestionAnswer, IQuestionFullInfo, IQuestionInputItem, ITestQuestionType } from 'models/testBank';
import MuiButton from 'components/UI/MuiButton';
import InfoIcon from '@mui/icons-material/Info';
import CustomForm from 'components/UI/CustomForm';
import { RemoveIcon } from 'components/UI/SvgIcons';
import PrimeReactModal from 'components/UI/PrimeReactModal';
import { useAlert } from 'contexts/alertContext';
interface Props
{
  open: boolean;
  selectedQuestion: IQuestion | null;
  editQuestionForm: IQuestionInputItem | null;
  onClose: () => void;
  onChange: (name: string, value: any) => void;
  onSubmit: (answers: IQuestionAnswer[], Points: number) => void;
  onDeleteAnswer: (answerId: number) => void;
  onEditAnswer: (answer: IQuestionAnswer) => void;
}


let defaultAnswer: IQuestionAnswer = {
  QuestionId: 0,
  Name: '',
  IsCorrectOption: false,
  Points: 0,
  imagePath: null,
}

const generateIcon = (iconName: String) =>
{
  switch (iconName)
  {
    case 'Single Choice':
      return <RadioButtonCheckedIcon />;
    case 'Multiple Choice':
      return <DomainVerificationIcon />;
    case 'Picture Choice':
      return <PhotoLibraryIcon />;
    default:
      return null;
  }
};

const EditQuestion: React.FC<Props> = ({ open, editQuestionForm, selectedQuestion, onClose, onChange, onSubmit, onDeleteAnswer, onEditAnswer }) =>
{
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [questionsTypes, setQuestionsTypes] = useState<ITestQuestionType[] | null>(null);
  const [answers, setAnswers] = useState<IQuestionAnswer[]>([defaultAnswer]);
  const queryClient = useQueryClient();
  const [questionDetail, setQuestionDetail] = useState<IQuestionFullInfo | null>(null);
  const { setAlert } = useAlert();

  const fetchQuestionDetail = useCallback(async () =>
  {
    setLoading(true);
    try
    {
      const res = await getQuestionById(selectedQuestion?.Id!);
      if (res.Success === 0) throw new Error(res.Message);
      setQuestionDetail(res.Data);
      res.Data && onChange('QuestionTypeId', res.Data.QuestionInfo.QuestionTypeId);
      onChange('hasOptionPoints', res.Data.Answers.find((item: IQuestionAnswer) => item.Points !== 0) ? true : false);
      setLoading(false);
    } catch (err: any)
    {
      setAlert({
        open: true,
        message: err?.response?.data?.Message || err.message || 'Something went wrong',
        type: 'error'
      });
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [selectedQuestion?.Id, setAlert]);


  const fetchQuestionTypes = useCallback(async () =>
  {
    setLoading(true);

    try
    {
      const response: ResponseResult = await queryClient.fetchQuery(['questionTypes'], getQuestionTypes);
      if (response.Success === 0) throw new Error(response.Message);
      setQuestionsTypes(response.Data);
      setLoading(false);
    } catch (err: Error | any)
    {
      setError(true);
      setLoading(false);
    }

    // eslint-disable-next-line
  }, [queryClient]);

  React.useEffect(() =>
  {
    fetchQuestionDetail();
    fetchQuestionTypes();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQuestion!.Id]);
  React.useEffect(() =>
  {
    if (questionDetail && questionDetail.Answers.length > 0)
    {
      setAnswers(questionDetail.Answers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionDetail]);

  if (!questionDetail || !questionsTypes) return <Skeleton animation='wave' variant="rectangular" width={ '100%' } height={ '100%' } />
  const questionTypeOptions: IOption[] = questionsTypes ? questionsTypes?.map((item: Language) => ({ label: item.Name, value: `${item.Id}`, icon: generateIcon(item.Name) })) : [];


  const handleChangeAnswers = (index: number, name: string, value: any) =>
  {
    let _answers = [...answers];
    _answers[index][name] = value;
    setAnswers(_answers);
  }

  const handleAddNewAnswer = (answer: IQuestionAnswer) =>
  {
    setAnswers([...answers, answer]);
  }


  const handleRemoveAnswer = (index: number) =>
  {
    // check answer is not the last one
    if (answers.length === 1)
    {
      return;
    }
    let _answers = [...answers];
    if (_answers[index]?.Id!) onDeleteAnswer(_answers[index]?.Id!);
    _answers.splice(index, 1);
    setAnswers(_answers);
  }
  const handleEditAnswer = (index: number) =>
  {
    let _answers = [...answers];
    onEditAnswer(_answers[index]);
  }

  let basicQuestionTypeFields: IFormField[] = [
    {
      name: 'QuestionTypeId',
      type: InputTypes.SELECT,
      options: questionTypeOptions,
      isHorizontal: true,
      placeholder: 'Select Question Type',
      defaultValue: {
        label: questionsTypes?.find((item: ITestQuestionType) => item.Id === questionDetail?.QuestionInfo.QuestionTypeId!)?.Name,
        value: `${questionsTypes?.find((item: ITestQuestionType) => item.Id === questionDetail?.QuestionInfo.QuestionTypeId!)?.Id}`,
      },
    }
  ];
  let pointField: IFormField[] = [
    {
      name: 'Points',
      type: InputTypes.NUMBER,
      isHorizontal: true,
      defaultValue: questionDetail.QuestionInfo?.Points!
    }
  ];
  let basicDescriptionFields: IFormField[] = [
    {
      name: 'Description',
      type: InputTypes.EDITOR,
      isHorizontal: true,
      defaultValue: questionDetail?.QuestionInfo.Description!
    }
  ];
  let basicIsRequiredFields: IFormField[] = [
    {
      name: 'IsRequired',
      label: 'Is Required',
      type: InputTypes.CHECKBOX,
      labelColor: "blue",
      checkBoxDirection: 'end',
      defaultValue: questionDetail?.QuestionInfo.IsRequired!
    }
  ];
  let basicIsRandomizeOptionsFields: IFormField[] = [
    {
      name: 'RandomizeOptions',
      label: 'Randomize Options',
      type: InputTypes.CHECKBOX,
      labelColor: "blue",
      checkBoxDirection: "end",
      defaultValue: questionDetail?.QuestionInfo.RandomizeOptions!
    }
  ];

  const getAnswersFields = (answer: IQuestionAnswer, index: number) =>
  {
    let Name: IFormField[] = [{
      name: `Name`,
      type: InputTypes.TEXT,
      placeholder: 'Enter Answer',
      isHorizontal: true,
      onChange: (e) =>
      {
        handleChangeAnswers(index, 'Name', e)
      },
      defaultValue: answer.Name,
      hidden: editQuestionForm?.QuestionTypeId! === '3' || editQuestionForm?.QuestionTypeId! === 3
    }];
    let imagePath: IFormField[] = [{
      name: `imagePath`,
      type: InputTypes.MULTIPLE_FILES,
      isHorizontal: true,
      onDeleteFile()
      {
        handleChangeAnswers(index, 'imagePath', null);
        onEditAnswer(answer);
      },
      limitFiles: 1,
      defaultImageUrl: answer.ImagePath ? `https://api.scofolio.com${answer.ImagePath}` : '',
      hidden: !editQuestionForm?.QuestionTypeId || editQuestionForm?.QuestionTypeId! === '1' || editQuestionForm?.QuestionTypeId! === '2' || editQuestionForm?.QuestionTypeId! === 1 || editQuestionForm?.QuestionTypeId! === 2
    }];
    let Points: IFormField[] = [{
      name: `Points`,
      type: InputTypes.TEXT,
      isHorizontal: false,
      placeholder: 'Enter Points',
      onChange: (e) =>
      {
        handleChangeAnswers(index, 'Points', e)
      },
      defaultValue: answer.Points,
      hidden: !editQuestionForm?.hasOptionPoints,
      customItem: <Stack direction={ 'row' } spacing={ 2 } alignItems={ 'center' } justifyContent={ 'space-between' }>
        <Tooltip title="Remove Answer" arrow>
          <IconButton onClick={ () => handleRemoveAnswer(index) }>
            <RemoveIcon />
          </IconButton>
        </Tooltip>
        <MuiButton
          variant="outlined"
          size="small"
          sx={ {
            fontWeight: 400,
            border: 'none',
            '&:hover': { color: '#0A94D1', border: 'none' }
          } }
          onClick={ () => handleEditAnswer(index) }>
          Save
        </MuiButton>
      </Stack>
    }];
    let IsCorrectOption: IFormField[] = [{
      name: `IsCorrectOption`,
      label: 'Is Correct',
      type: InputTypes.CHECKBOX,
      isHorizontal: true,
      onChange: (e) =>
      {
        handleChangeAnswers(index, 'IsCorrectOption', e)
      },
      hidden: editQuestionForm?.hasOptionPoints,
      defaultValue: answer.IsCorrectOption,
      customItem: <Stack direction={ 'row' } spacing={ 2 } alignItems={ 'center' } justifyContent={ 'space-between' }>
        <Tooltip title="Remove Answer" arrow>
          <IconButton onClick={ () => handleRemoveAnswer(index) }>
            <RemoveIcon />
          </IconButton>
        </Tooltip>
        <MuiButton
          variant="outlined"
          size="small"
          sx={ {
            fontWeight: 400,
            border: 'none',
            '&:hover': { color: '#0A94D1', border: 'none' }
          } }
          onClick={ () => handleEditAnswer(index) }>
          Save
        </MuiButton>
      </Stack>
    }];


    return {
      Name,
      imagePath,
      Points,
      IsCorrectOption
    };


  }





  if (error)
    return (
      <Grid container justifyContent={ 'center' } alignItems={ 'center' } height={ '9.50vh' }>
        <Typography variant="body2" fontWeight={ 500 } fontSize={ 15 } color={ '#000' }>
          Something Wrong
        </Typography>
      </Grid>
    );

  if (loading) return <ActionLoader position={ 'absolute' } />;

  return (
    <PrimeReactModal
      open={ open }
      onClose={ onClose }
      title="Edit Question"
      children={
        <Container >

          <Grid container alignItems={ 'center' } spacing={ 2 }>
            <Grid item lg={ 8 } md={ 12 }>
              <Stack direction={ 'row' } spacing={ 2 } alignItems={ 'center' }>
                <Typography variant="body2" fontWeight={ 500 } fontSize={ 15 } color={ '#000' } width={ '20%' }>
                  Question Type:
                </Typography>
                <Stack direction={ 'row' } spacing={ 2 } alignItems={ 'center' } sx={ { width: '80%' } }>
                  <CustomForm fields={ basicQuestionTypeFields } onChange={ onChange } />
                  <IconButton sx={ { width: '10px', height: '10px' } }>
                    <InfoIcon />
                  </IconButton>
                </Stack>
              </Stack>
            </Grid>


            <Grid item lg={ 4 } md={ 12 }>
              <Stack direction={ 'row' } spacing={ 2 } alignItems={ 'center' }>
                <Typography variant="body2" fontWeight={ 500 } fontSize={ 15 } color={ '#000' }>
                  Points:
                </Typography>
                <CustomForm fields={ pointField } onChange={ onChange } />
              </Stack>
            </Grid>

            <Grid item lg={ 12 }>
              <Stack direction={ 'row' } spacing={ 2 } alignItems={ 'center' }>
                <Typography variant="body2" fontWeight={ 500 } fontSize={ 15 } color={ '#000' } width={ '13%' }>
                  Question:
                </Typography>
                <CustomForm fields={ basicDescriptionFields } onChange={ onChange } width={ '87%' } />
              </Stack>
            </Grid>

            <Grid item lg={ 12 }>
              <Grid container direction={ 'row' } spacing={ 2 } alignItems={ 'center' } justifyContent={ 'flex-end' }>
                <CustomForm fields={ basicIsRequiredFields } onChange={ onChange } />
              </Grid>
            </Grid>


            <Grid item lg={ 12 }>

              <Stack direction={ 'row' } spacing={ 2 } alignItems={ 'start' }>
                <Typography variant="body2" fontWeight={ 500 } fontSize={ 15 } color={ '#000' } width={ '13%' }>
                  Options:
                </Typography>
                <Stack spacing={ 2 } alignItems={ 'center' } width={ '90%' }>
                  { answers.map((answer, i) => (
                    <Grid container key={ i } spacing={ 1 }>
                      <Grid item xs={ 12 } md={ 5 }>
                        <CustomForm isHorizontal={ true } fields={ getAnswersFields(answer, i).Name }
                          onChange={ (name: string, value: any) => handleChangeAnswers(i, name, value) }
                        />
                        <CustomForm isHorizontal={ true } fields={ getAnswersFields(answer, i).imagePath }
                          onChange={ (name: string, value: any) => handleChangeAnswers(i, name, value) }
                        />
                      </Grid>
                      <Grid item xs={ 12 } md={ 2 }>
                        <CustomForm fields={ getAnswersFields(answer, i).Points }
                          onChange={ (name: string, value: any) => handleChangeAnswers(i, name, value) }
                        />
                      </Grid>
                      <Grid item xs={ 12 } md={ 5 }>
                        <CustomForm isHorizontal={ true } fields={ getAnswersFields(answer, i).IsCorrectOption }
                          onChange={ (name: string, value: any) => handleChangeAnswers(i, name, value) }
                        />
                      </Grid>
                      <Divider sx={ { width: '100%' } } />
                    </Grid>
                  )) }
                  <Grid container alignItems={ 'center' } >
                    <Grid item lg={ 6 } my={ 2 }>
                      <Grid container direction={ 'row' } spacing={ 2 } alignItems={ 'center' } justifyContent={ 'flex-start' }>
                        <MuiButton variant="contained" size="large"
                          sx={ {
                            borderRadius: 1,
                            mx: 2
                          } }
                          onClick={ () => handleAddNewAnswer(defaultAnswer) }>
                          Add Option
                        </MuiButton>
                      </Grid>
                    </Grid>

                    <Grid item lg={ 6 }>
                      <Grid container direction={ 'row' } spacing={ 2 } alignItems={ 'center' } justifyContent={ 'flex-end' }>
                        <CustomForm fields={ basicIsRandomizeOptionsFields } onChange={ onChange } />
                      </Grid>
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>
            </Grid>

          </Grid>
        </Container>


      }
      actions={ <>
        <MuiButton
          variant="outlined"
          size="medium"
          sx={ {
            backgroundColor: '#F0F5FD',
            color: '#8B8BAE',
            fontWeight: 400,
            border: 'none',
            '&:hover': { border: 'none' }
          } }
          onClick={ onClose }>
          Cancel
        </MuiButton>
        <MuiButton
          variant="outlined"
          size="medium"
          sx={ {
            backgroundColor: '#0A94D1',
            color: '#fff',
            fontWeight: 400,
            border: 'none',
            '&:hover': { color: '#0A94D1', border: 'none' }
          } }
          disabled={ loading }
          onClick={ () => onSubmit(answers, questionDetail.QuestionInfo.Points!) }>
          Save
          <CircularProgress size={ 10 } sx={ { mx: 2, display: loading ? '' : 'none' } } />
        </MuiButton>
      </> }
    />
  );
};

export default EditQuestion;
